.answer {
  color: black;
  border-radius: var(--border-radius-md);
  text-align: center;
  padding: 8px 12px;
  align-self: stretch;
  font-weight: var(--font-weight--semibold);
}

.correctAnswer {
  color: white;
  border: 1px solid var(--color-beige-3);
}

.yourCorrectAnswer {
  color: black;
  background: #ffdc81;
}

.incorrectAnswer {
  background: #eaeaea;
}

.answersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 12px 0;
  justify-items: stretch;
  align-self: stretch;
}

.answerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.answerHeader {
  font-weight: var(--font-weight--bold);
  margin-bottom: 12px;
  text-align: center;
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.questionContainer {
  margin-top: 12px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: 2px solid var(--color-beige-2);

  border-radius: var(--border-radius-md);
}


.pageHeaderText {
  text-align: center;
  text-transform: uppercase;
  margin: 8px 0 0;
  font-size: 14px;
}

.page {
  padding: 0 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: linear-gradient(to left, var(--background-linear-gradient-3), var(--background-linear-gradient-4));
}

.nextPageButton {
  margin: 12px 0;
  text-transform: uppercase;
}
