import React, { FC } from 'react'
import * as styles from './ShareScore.module.css'
import strings from '../../utils/strings'

const ShareScore: FC = () => (
  <>
    {/* <img className={styles.ticket} src={phoneticket} /> */}
    <div className={styles.hashtag}>{strings.shareScore}</div>
    <div className={styles.text}>{strings.shareScore1}</div>
    <div className={styles.text}>{strings.shareScore2}</div>
    <div className={styles.text}>
      {strings.shareScore3}
      <a className={styles.link} href="https://yle.fi/aihe/hengaillaan">
        yle.fi/aihe/hengaillaan
      </a>
    </div>
  </>
)

export default ShareScore
