import React, { FC } from 'react'
import clsx from 'clsx'
import * as styles from './correctanswerspage.module.css'
import { PageLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import ROUTES from '../../utils/routes'
import strings from '../../utils/strings'
import { Answer } from '../../models/answer'
import { ChevronRight, Close } from '@yleisradio/yds-icons-react'
import { Option, Question } from '../../models/question'
import { getQuestions } from '../../utils/episodes'
import { AnswerData } from '../../hooks/useAnswers'
import { EpisodeData } from '../../hooks/useEpisode'
import { useNavigate } from 'react-router-dom'
import { getChosenOption, isCorrectlyAnswered } from '../../utils/answers'
import { RichText } from '../../components/RichText/RichText'
import Button from '../../components/Button/Button'

import { Check } from '@yleisradio/yds-icons-react'

type CorrectAnswersPageParams = {
  episodeId: string
  answerData: AnswerData
  episodeData: EpisodeData
}

const IncorrectAnswer = ({ option }: { option?: Option }) => (
  <div className={styles.answerContainer}>
    <div className={styles.answerHeader}>
      {option !== undefined ? (
        <>
          <Close style={{ color: 'var(--color-red-incorrect)' }} />
          {strings.yourIncorrectAnswer}
        </>
      ) : (
        strings.noAnswer
      )}
    </div>
    {option !== undefined && (
      <>
        <div className={clsx(styles.answer, styles.incorrectAnswer)}>{option.text}</div>
      </>
    )}
  </div>
)

const CorrectAnswer = ({ option, isCorrect }: { option?: Option; isCorrect: boolean }) => (
  <div className={styles.answerContainer}>
    <div className={styles.answerHeader}>
      <Check style={{ color: isCorrect ? 'var(--color-green--live)' : 'white' }} />
      {isCorrect ? strings.yourCorrectAnswer : strings.correctAnswer}
    </div>
    <div
      className={clsx(styles.answer, styles.correctAnswer, isCorrect && styles.yourCorrectAnswer)}
    >
      {option?.text}
    </div>
  </div>
)

const Answer = ({ question, answer }: { question: Question; answer?: Answer }) => {
  const isCorrect = isCorrectlyAnswered({ answer, question })
  const correctOption = question.options.find(({ correct }) => correct)
  const chosenOption = getChosenOption({ answer, question })
  return (
    <div className={styles.questionContainer}>
      <div>
        <RichText disableImages content={question.content?.text} />
      </div>
      <div className={styles.answersContainer}>
        <CorrectAnswer option={correctOption} isCorrect={isCorrect} />
        {!isCorrect && <IncorrectAnswer option={chosenOption} />}
      </div>
    </div>
  )
}

const CorrectAnswersPage: FC<CorrectAnswersPageParams> = ({
  episodeId,
  answerData,
  episodeData
}) => {
  const navigate = useNavigate()
  const [episode, isEpisodeLoading] = episodeData
  const { answers, isLoading: isAnswersLoading } = answerData
  const questions = getQuestions(episode)
  const correctAnswerItems = questions?.map(question => {
    const answer = answers.find(({ questionUuid }) => questionUuid === question.uuid)
    return <Answer key={question.uuid} question={question} answer={answer} />
  })

  const isLoading = isEpisodeLoading || isAnswersLoading

  if (isLoading) {
    return (
      <div className={styles.page}>
        <PageLoadingIndicator color="white" />
      </div>
    )
  }

  return (
    <div className={styles.page}>
      <h2 className={styles.pageHeaderText}>{strings.correctAnswers}</h2>
      {correctAnswerItems}
      <Button
        className={styles.nextPageButton}
        iconAfter={<ChevronRight />}
        onClick={() => navigate(`${ROUTES.gamepage}/${episodeId}${ROUTES.scorepage}`)}
      >
        {strings.checkScore}
      </Button>
    </div>
  )
}

export default CorrectAnswersPage
