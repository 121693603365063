import React, { FC } from 'react'
import * as styles from './ScoreTicket.module.css'
import strings from '../../utils/strings'

type ScoreTicketProps = {
  score: number
  maxScore: number
}

const ScoreTicket: FC<ScoreTicketProps> = ({ score, maxScore }) => (
  <div className={styles.ticket}>
    <div />
    <div className={styles.header}>{strings.yourScore}</div>
    <div className={styles.score}>{`${score}/${maxScore}`}</div>
    <div className={styles.footer}>{strings.scoreTitle(score)}</div>

    <div />
    <div className={styles.hashtag}>{strings.hashtag}</div>
  </div>
)

export default ScoreTicket
