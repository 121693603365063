.background {
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  background: url(background.webp);
  background-size: cover;
  flex: 1;
  position: relative;
}

.blur {
  backdrop-filter: blur(5px);
}

.purple {
  display: flex;
  flex: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0),
      var(--background-linear-gradient-2) 50%,
      var(--background-linear-gradient-3));
}

.white {
  display: flex;
  flex: 1;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5));
}

.padding {
  padding: 0 8px 8px 8px;
  flex: 1;
  display: flex;
}

.goldenEdgeS {
  position: absolute;
  width: 2px;
  background-color: var(--color-beige-3);
  height: 100%;
  left: 16px;
  top: 16px;
  box-shadow: 0 0 1px 3px var(--color-beige-2);
}

.goldenEdge {
  pointer-events: none;
  position: absolute;
  opacity: .75;
  left: 16px;
  top: 16px;
  right: 16px;
  bottom: 16px;
  border: 1px solid var(--color-beige-3);
  border-bottom: none;
  border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
  box-shadow: 0 0 3px 1px var(--color-beige-2), inset 0 0 3px 1px var(--color-beige-2);
}
