.separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 16px 0;
  color: black;
}

.dash {
  flex: 1;
  border-top: 1px dashed black;
  height: 0;
}

.shareScore {
  padding: 0 12px;
}
