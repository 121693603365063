.ticket {
  background: url(kultainen_lippu_varjolla.webp) no-repeat;
  background-size: contain;
  display: grid;
  width: 300px;
  height: 357px;
  grid-template-rows: 12px 98px 82px 58px 36px 55px;
  align-items: center;
  color: #3f3d38;
}

.header {
  color: #3f3d38;
  text-align: center;
  font-weight: var(--font-weight--bold);
  font-size: 42px;
}

.score {
  text-align: center;
  font-weight: var(--font-weight--bold);
  font-size: 64px;
  line-height: 100px;
}

.footer {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;

  font-weight: var(--font-weight--bold);
}


.hashtag {
  text-align: center;
  text-transform: uppercase;
  font-weight: var(--font-weight--bold);
  font-size: 20px;
}
