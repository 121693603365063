.ticket {
  width: 120px;
  margin: 16px 0;
  color: black;
}

.hashtag {
  font-weight: var(--font-weight--bold);
  font-size: 24px;
  color: black;
}

.text {
  margin: 16px 24px;
  text-align: center;
  color: black;
}

.link {
  font-weight: var(--font-weight--bold);
  align-self: center;
  color: black;
}
