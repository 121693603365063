import { ChevronRight } from '@yleisradio/yds-icons-react'
import React, { FC } from 'react'
import strings from '../../utils/strings'
import Button from '../Button/Button'
import { TrainInfoModal } from '../Modal/Modal'
import * as styles from './gameIsOnModal.module.css'

type GameIsOnModalProps = {
  question: number
  onPlay: () => void
}

const GameIsOnModal: FC<GameIsOnModalProps> = ({ onPlay }) => {
  return (
    <TrainInfoModal
      button={
        <Button variant="shinyGold" iconAfter={<ChevronRight />} onClick={onPlay}>
          {strings.toPlay}
        </Button>
      }
      header={<h2 className={styles.gameIsOnHeader}>{strings.gameIsOn}</h2>}
      content={<p>{strings.gameIsOnNextRound}</p>}
    />
  )
}

export default GameIsOnModal
