import dayjs from 'dayjs'
import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../components/BackButton/BackButton'
import Background from '../../components/Background/Background'
import Button from '../../components/Button/Button'
import { PageLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import { ChevronRight } from '@yleisradio/yds-icons-react'
import GameIsOnModal from '../../components/GameIsOnModal/GameIsOnModal'
import { AnswerData } from '../../hooks/useAnswers'
import { EpisodeData } from '../../hooks/useEpisode'
import { useIsTimePassed } from '../../hooks/useIsTimePassed'
import { getOngoingSection } from '../../utils/episodes'
import ROUTES from '../../utils/routes'
import strings from '../../utils/strings'
import * as styles from './gamelobbypage.module.css'
import SignBoard from '../../components/SignBoard/SignBoard'
import MessageContainer from '../../components/MessageContainer/MessageContainer'
import LiveIndicator from '../../components/LiveIndicator/LiveIndicator'
import { scrollToEnd } from '../../utils/scroll'

type GameLobbyPageParams = {
  episodeId: string
  episodeData: EpisodeData
  answerData: AnswerData
}

const GameLobbyPage: FC<GameLobbyPageParams> = ({ episodeId, episodeData, answerData }) => {
  const [episode, isLoading] = episodeData
  const { answers } = answerData
  const navigate = useNavigate()
  const firstSection = episode?.questions[0]
  const isStartTimePassed = useIsTimePassed(firstSection?.settings.startTime || null)
  const isEndTimePassed = useIsTimePassed(firstSection?.settings.endTime || null)
  const isFirstSectionOpen = isStartTimePassed && !isEndTimePassed
  const now = dayjs()
  const ongoingSection = getOngoingSection({ sections: episode?.questions, now })
  const isSecondOrThirdSectionOpen =
    !isFirstSectionOpen && ongoingSection && ongoingSection !== firstSection

  useEffect(() => {
    const shouldNavigateToResults =
      answers.length > 0 && ongoingSection === undefined && isEndTimePassed
    if (shouldNavigateToResults) {
      navigate(`${ROUTES.gamepage}/${episodeId}${ROUTES.waitForResults}`)
    }
  }, [answers, ongoingSection, isEndTimePassed, navigate, episodeId])

  useEffect(() => {
    if (isFirstSectionOpen || isSecondOrThirdSectionOpen) {
      scrollToEnd()
    }
  }, [isFirstSectionOpen, isSecondOrThirdSectionOpen])

  if (isLoading) {
    return (
      <Background overlay="purple">
        <PageLoadingIndicator color="white" />
      </Background>
    )
  }

  return (
    <Background overlay="purple">
      <div className={styles.container}>
        <SignBoard>
          <MessageContainer
            left={<LiveIndicator episodeName={episode?.name || ''} isLive={true} />}
            title={episode?.mainText || ''}
            text={`${strings.departure} ${episode?.startTime?.format(strings.timeFormat)}`}
          />
        </SignBoard>
        <h1>{isFirstSectionOpen ? strings.gameHasStarted : strings.gameStartsSoon}</h1>
        <p>{strings.gameTip2}</p>
        <p>{strings.gameTip3}</p>
        <p>{strings.pointsInfo}</p>
        <p style={{ marginBottom: 24 }}>{strings.pointsInfo2}</p>
        <Button
          className={styles.nextButton}
          iconAfter={<ChevronRight />}
          isDisabled={!isFirstSectionOpen}
          variant="shinyGold"
          onClick={() => {
            navigate(`${ROUTES.gamepage}/${episodeId}`)
          }}
        >
          {strings.moveToSection}
        </Button>
        <BackButton className={styles.backButton} toRoute={ROUTES.frontpage} noIcon>
          {strings.goBackToFrontpage}
        </BackButton>

        {isSecondOrThirdSectionOpen && (
          <GameIsOnModal
            question={ongoingSection.orderNumber}
            onPlay={() => {
              navigate(
                `${ROUTES.gamepage}/${episodeId}?jumpToSection=${ongoingSection.orderNumber}`
              )
            }}
          />
        )}
      </div>
    </Background>
  )
}

export default GameLobbyPage
