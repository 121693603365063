import React, { FC } from 'react'
import Square from '../Square/Square'
import * as styles from './logo.module.css'
import logo from './logo2025.webp'
import hengaillaan from './hengaillaan.webp'

const Logo: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Square shape="roundedBottom" hasShadow>
          <img className={styles.image} src={logo} />
        </Square>
      </div>
      <div className={styles.textImageContainer}>
        <img className={styles.textImage} src={hengaillaan} />
      </div>
    </div>
  )
}

export default Logo
