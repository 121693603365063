const nthQuestion = (n: number) => {
  switch (n) {
    case 2:
      return 'toiselle'
    case 3:
      return 'kolmannelle'
    case 4:
      return 'neljännelle'
    default:
      return `${n.toString()}.`
  }
}

const nth = (n: number) => {
  switch (n) {
    case 1:
      return 'Ensimmäinen'
    case 2:
      return 'Toinen'
    case 3:
      return 'Kolmas'
    case 4:
      return 'Neljäs'
    default:
      return `${n.toString()}.`
  }
}

const scoreTitle = (n: number) => {
  switch (n) {
    default:
    case 0:
      return 'Maitojunailija'
    case 1:
      return 'Mattimyöhäinen'
    case 2:
      return 'Mattimyöhäinen'
    case 3:
      return 'Seuramatkaaja'
    case 4:
      return 'Seuramatkaaja'
    case 5:
      return 'Pakettimatkaaja'
    case 6:
      return 'Pakettimatkaaja'
    case 7:
      return 'Reppureissaaja'
    case 8:
      return 'Reppureissaaja'
    case 9:
      return 'Matkanjohtaja'
    case 10:
      return 'Matkanjohtaja'
    case 11:
      return 'Sohvasurffari'
    case 12:
      return 'Jetsettaaja'
    case 13:
      return 'Maailmanmatkaaja'
    case 14:
      return 'Super-hengailija'
  }
}

const percentage = (p: number) => `${Math.round(p * 100)}\u00A0%`

const strings = {
  allEpisodes: 'Menneet ja tulevat jaksot',
  chooseOption: 'Valitse yksi vaihtoehto',
  close: 'Sulje',
  goBack: 'Takaisin',
  goBackToFrontpage: 'Takaisin etusivulle',
  giveFeedback: 'Anna palautetta',
  giveFeedbackText:
    'Paina nappia ja lähetä meille terveisiä, miten voisimme parantaa Hengaillaan-kotipeliä.',
  nextQuestion: 'Pelaa nyt',
  youMustLogin: 'Sinun täytyy kirjautua Yle Tunnuksella osallistuaksesi peliin.',
  lockAnswer: 'Lukitse vastaus',
  live: 'Live',
  pastEpisodes: 'Menneet jaksot',
  frontpageText: 'Tervetuloa pelaamaan Hengaillaan-kotipeliä suoran lähetyksen aikana.',
  frontpageText2: 'Seuraa Jenspan ohjeita lähetyksessä ja pelaa mukana.',
  play: 'Pelaa kotipeliä',
  info: 'Ohjeet ja anna palautetta',
  instructions: 'Kotipelin ohjeet',
  instructionText1:
    'Voit pelata Hengaillaan-kotipeliä lauantai-iltaisin, suoran lähetyksen aikaan.',
  instructionText2:
    'Kotipeliä varten tarvitset älypuhelimen, Yle-tunnuksen sekä Yle-sovelluksen, jonka voit ladata maksutta sovelluskaupoista.',
  instructionText3:
    'Neljätoista kotipelikysymystä aukeavat kaksi kerrallaan, tasatahtiin suoran lähetyksen aikana. Kysymykset ovat samoja, mitä lähetyksessä esitetään studiokilpailijoille.',
  instructionText4:
    'Jenspa ilmoittaa milloin on aika vastata kotipelin kysymyksiin. Vastausaikaa on vähän - ole siis nopea!',
  instructionText5: 'Oikeasta vastauksesta saat yhden pisteen, väärästä nolla pistettä.',
  instructionText6: 'Jaksokohtainen pistepottisi paljastuu kaikkien kotipelikysymysten jälkeen.',
  instructionText7:
    'Pelaa joka viikko ja kerää koko kauden pisteet talteen liittämällä pistepottisi tulostaulukkoon. Koko kauden paras pelaaja palkitaan.',
  instructionText8:
    'Jakamalla kuvakaappauksen illan pelituloksestasi Instagramissa tai Facebookissa tunnisteella #hengaillaan osallistut viikoittaiseen pääsylippujen arvontaan Hengaillaan-studiokatsomoon Tampereen Mediapoliksessa.',
  instructionText9: 'Arvonnan tarkemmat ohjeet ja ehdot osoitteessa ',
  departure: 'Lähtö',
  downloadYleApp: 'Lataa Yle-sovellus ja pelaa Hengaillaan-kotipeliä älypuhelimellasi.',
  timeFormat: 'DD.MM. klo HH:mm',
  toPlay: 'Pelaa nyt',
  question: 'Kysymys',
  questionPair: 'Kysymyspari',
  upcomingEpisodes: 'Tulevat jaksot',
  showResults: 'Näytä tulostaulu',
  shareScore: 'Jaa tuloksesi',
  forward: 'Eteenpäin',
  timeEnded: 'Aika loppui!',
  youWereLate: 'Pahus! Et ehtinyt vastaamaan kaikkiin tämän kierroksen kysymyksiin.',
  postScoreToLeaderboard: 'Jaa tuloksesi pistetauluun',
  seeScoresAfterQuestions: 'Näet oikeat vastaukset kaikkien kysymysten jälkeen. ',
  questionWillOpenLater: 'Odottele hetkinen. Jenspa ilmoittaa, milloin jatketaan. ',
  yourScore: 'Pisteesi',
  correct: 'Oikein',
  game: 'Kotipeli',
  gameStartsSoon: 'Kotipeli alkaa aivan kohta!',
  gameHasStarted: 'Pelaa nyt kotipeliä!',
  welcome: 'Tervetuloa pelaamaan Hengaillaan-kotipeliä',
  gameTip2:
    'Illan suoran lähetyksen aikana kotipelikysymyksiä tulee yhteensä 14 kappaletta. Kysymykset aukeavat pareittain.',
  gameTip3:
    'Jenspa ilmoittaa milloin on aika vastata kotipelin kysymyksiin. Vastausaikaa ei ole loputtomiin - ole siis nopea!',
  answersSaved: 'Hyvin pelattu, jatketaan kohta uusilla kysymyksillä.',
  gameIsOpen: 'Siirry nyt pelaamaan. Vastaa nopeasti, aikaa ei ole liikaa!',
  gameIsNotOpen: 'Kotipeli ei ole vielä pelattavissa',
  gameIsOn: 'Peli käynnissä!',
  gameIsOnNthQuestion: (n: number) => `Kotipeli on edennyt ${nthQuestion(n)} kierrokselle.`,
  gameIsOnNextRound: 'Kotipeli on edennyt seuraavalle kierrokselle',
  moveToSection: 'Pelaa nyt',
  allQuestionsAnswered: 'Kaikki kotipelin kysymykset on nyt pelattu',
  checkScore: 'Katso pisteesi',
  CheckAndParticipate:
    'Katso illan kokonaispisteesi, osallistu tulostauluun ja jaa tuloksesi somessa',
  hashtag: '#hengaillaan',
  youAnsweredXQuestions: ({
    answerCount,
    questionCount
  }: {
    answerCount: number
    questionCount: number | undefined
  }) => `Olet vastannut ${answerCount}/${questionCount} kotipelin kysymyksistä`,
  correctAnswers: 'Oikeat vastaukset',
  scoreboard: 'Tulostaulu',
  stillCalculating: 'Tulostasi lasketaan vielä, palaa hetken kuluttua',
  leaveLeaderboard: 'Poista osallistumisesi',
  joinLeaderboard: 'Osallistu tulostauluun',
  rank: 'Sija',
  player: 'Pelaaja',
  points: 'Pisteet',
  toLeaderboard: 'Pistetauluun',
  correctAnswer: 'Oikea vastaus',
  yourCorrectAnswer: 'Vastasit oikein!',
  yourIncorrectAnswer: 'Sinä vastasit',
  noAnswer: 'Et vastannut mitään',
  nthRound: (n: number) => `${nth(n)} kierros`,
  betterThan: (p: number) =>
    `Hyvin sä vedät! Onnistuit paremmin kuin ${percentage(p)} muista pelaajista!`,
  zeroPoints: (p: number) =>
    `Et ollut ainoa, myös ${percentage(p)} muista pelaajista jäi pisteittä.`,
  scoreTitle,
  shareScore1: 'Jaa ruutukaappaus tästä sivusta julkisesti Instagramissa tai Facebookissa.',
  shareScore2:
    'Liitä mukaan tunniste #hengaillaan ja olet mukana viikottaisessa lippujen arvonnassa Hengaillaan-studiokatsomoon Tampereen Mediapoliksessa.',
  shareScore3: 'Arvonnan tarkemmat ohjeet ja ehdot osoitteessa ',
  pointsInfo: 'Oikeasta vastauksesta saat yhden pisteen, väärästä nolla pistettä.',
  pointsInfo2:
    'Illan pistepottisi paljastuu ohjelman lopussa. Kerää koko kauden pisteet talteen liittymällä tulostaulukkoon.'
}

export default strings
