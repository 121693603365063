import React, { FC, useContext } from 'react'
import { useLeaderboard } from '../../hooks/useLeaderboard'
import YleTunnusContext from '../../state/yleTunnus/context'
import { PlayerScore } from '../../models/playerScore'
import * as styles from './resultspage.module.css'
import config from '../../config'
import BackButton from '../../components/BackButton/BackButton'
import { PageLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import strings from '../../utils/strings'
import { useParticipation } from '../../hooks/useParticipation'
import Button from '../../components/Button/Button'

interface ScoreItemProps {
  score: number
  nickname: string
  orderNumber: number
  highlight: boolean
}

const ScoreItem: FC<ScoreItemProps> = ({ orderNumber, nickname, score, highlight }) => {
  return (
    <div className={highlight ? styles.highlightedItem : styles.scoreItem}>
      <div className={styles.leftCell}>{orderNumber}</div>
      <div className={styles.centerCell}>{nickname}</div>
      <div className={styles.rightCell}>{score}</div>
    </div>
  )
}

interface ResultsArrayProps {
  leaderboard: PlayerScore[]
  user?: string
  participate: boolean | null
}

const ResultsArray: FC<ResultsArrayProps> = ({ leaderboard, user, participate }) => {
  /* The leaderboard endpoint has a 60sec cache. Let's make the experience better
   * by not showing the user on the list if they've removed their participation */
  const filteredLeaderboard = leaderboard.filter(item => {
    if (!participate) {
      return item.nickname !== user
    } else {
      return true
    }
  })
  const userPosition = filteredLeaderboard.find(obj => obj.nickname === user)
  const userOrderNumber = userPosition?.orderNumber
  const userScore = userPosition?.score
  const maxItems = 100
  const userIsOnList = userOrderNumber && userOrderNumber <= maxItems
  const userHasScore = userOrderNumber && typeof userScore === 'number'
  /* If the user position is outside of max rendered items, we show it
   * at the bottom of the list. If the user's score is not yet on the
   * leaderboard, we show a placeholder telling the user to come back later. */
  let UserScore
  if (participate && user && !userIsOnList && userHasScore) {
    UserScore = (
      <ScoreItem highlight nickname={user} orderNumber={userOrderNumber} score={userScore} />
    )
  } else if (participate && !userHasScore) {
    UserScore = (
      <div className={styles.highlightedItem}>
        <div className={styles.leftCell}>?</div>
        <div className={styles.centerCell}>{strings.stillCalculating}</div>
        <div className={styles.rightCell}>?</div>
      </div>
    )
  }

  const items = filteredLeaderboard
    .slice(0, maxItems)
    .map(leaderboardItem => (
      <ScoreItem
        key={leaderboardItem.orderNumber}
        highlight={userOrderNumber === leaderboardItem.orderNumber}
        {...leaderboardItem}
      />
    ))

  return (
    <div className={styles.scoreList}>
      <div className={styles.scoreHeader}>
        <div className={styles.edgeHeader}>{strings.rank}</div>
        <div className={styles.centerHeader}>{strings.player}</div>
        <div className={styles.edgeHeader}>{strings.points}</div>
      </div>
      {items}
      {UserScore}
    </div>
  )
}

interface ParticipationButtonProps {
  participate: boolean | null
  disabled: boolean
  onClick: (participate: boolean) => void
}

const ParticipationButton: FC<ParticipationButtonProps> = ({ participate, disabled, onClick }) => {
  return (
    <Button
      className={styles.joinButton}
      variant={participate ? 'transparent' : 'shinyGold'}
      isDisabled={disabled}
      onClick={() => onClick(participate ? false : true)}
    >
      {participate ? strings.leaveLeaderboard : strings.joinLeaderboard}
    </Button>
  )
}

const Resultspage = () => {
  const [leaderboard, refreshLeaderboard, isLoading] = useLeaderboard(config.tehtavaSeriesId)
  const {
    participate,
    postParticipation,
    isLoading: isParticipationLoading
  } = useParticipation(config.tehtavaSeriesId)
  const currentUser = useContext(YleTunnusContext).user?.nick

  if (isLoading) {
    return (
      <div className={styles.resultspage}>
        <PageLoadingIndicator color="white" />
      </div>
    )
  }

  return (
    <div className={styles.resultspage}>
      <BackButton />
      <div className={styles.overlay} />
      <div className={styles.header}>
        <h2>{strings.scoreboard}</h2>
        <ParticipationButton
          participate={participate}
          disabled={isParticipationLoading}
          onClick={participate => postParticipation(participate).then(() => refreshLeaderboard())}
        />
      </div>
      <ResultsArray leaderboard={leaderboard} user={currentUser} participate={participate} />
    </div>
  )
}

export default Resultspage
