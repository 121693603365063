.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.container>h1 {
  max-width: 300px;
  text-align: center;

  color: var(--color-black-1);
}

.container>p {
  color: white;
  max-width: 300px;
  width: 80%;
  text-align: center;
  margin: 12px 0;

  color: var(--color-black-1);
}

.backButton {
  margin-top: auto;
  margin-bottom: 16px;
}

.nextButton {
  max-width: 300px;
  width: 75%;
}
