import React, { FC } from 'react'
import * as styles from './question.module.css'
import Button from '../Button/Button'
import strings from '../../utils/strings'
import { Option, Question as QuestionModel } from '../../models/question'
import { Answer } from '../../models/answer'
import DevNextButton from '../DevTools/DevNextButton'
import { RichText } from '../RichText/RichText'
import { UnsavedAnswer } from '../../hooks/useAnswers'
import { useIsTimePassed } from '../../hooks/useIsTimePassed'
import ToggleButton from '../ToggleButton/ToggleButton'
import { ChevronRight } from '@yleisradio/yds-icons-react'
import { scrollToEnd } from '../../utils/scroll'

type QuestionProps = {
  question: QuestionModel
  section: QuestionModel
  answer?: Answer
  onAnswer: (answer: UnsavedAnswer) => Promise<void>
  onSkipQuestion: () => void
}

const Question: FC<QuestionProps> = ({ section, question, answer, onAnswer, onSkipQuestion }) => {
  const [selectedOption, setSelectedOption] = React.useState<Option>()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)
  const isTimeStarted = useIsTimePassed(section.settings.startTime)

  const onSubmit = async () => {
    if (selectedOption) {
      setIsLoading(true)
      await onAnswer({ question, option: selectedOption })
      setIsLoading(false)
    }
  }

  const onSelect = (option: Option) => {
    setSelectedOption(option)
    scrollToEnd()
  }

  React.useEffect(() => {
    setSelectedOption(undefined)
    setIsSubmitted(false)
  }, [question.uuid])

  React.useEffect(() => {
    const isSubmitted = Boolean(answer) && selectedOption === undefined
    if (isSubmitted) {
      // when user re-enters closed game or refreshes the page, we have to skip the question if its already submitted
      onSkipQuestion()
    } else if (answer) {
      setIsSubmitted(true)
      setIsLoading(false)
    }
  }, [question, answer, selectedOption, onSkipQuestion])

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.questionText}>
          <RichText content={question.content?.text} />
        </p>
        <div className={styles.optionContainer}>
          {question.options.map(option => (
            <div key={option.id} className={styles.option}>
              <ToggleButton
                isDisabled={isLoading || isSubmitted}
                onClick={() => onSelect(option)}
                isPressed={option.id === selectedOption?.id}
              >
                {option.text}
              </ToggleButton>
            </div>
          ))}
        </div>
        <p className={styles.hintText}>
          {selectedOption === undefined ? strings.chooseOption : ''}
        </p>
      </div>
      <DevNextButton onClick={onSkipQuestion} />
      <Button
        className={styles.center}
        isDisabled={!selectedOption || isLoading || isSubmitted || !isTimeStarted}
        isLoading={isLoading}
        onClick={onSubmit}
        variant="shinyGold"
        iconAfter={<ChevronRight />}
      >
        {strings.lockAnswer}
      </Button>
    </div>
  )
}

export default Question
