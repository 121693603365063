import React, { FC, useEffect } from 'react'
import { ChevronRight } from '@yleisradio/yds-icons-react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import InfoModal from '../../components/InfoModal/InfoModal'
import { PageLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import ROUTES from '../../utils/routes'
import strings from '../../utils/strings'
import * as styles from './frontpage.module.css'
import { useSeries } from '../../hooks/useSeries'
import Background from '../../components/Background/Background'
import Logo from '../../components/Logo/Logo'
import SignBoard from '../../components/SignBoard/SignBoard'
import MessageContainer from '../../components/MessageContainer/MessageContainer'
import LiveIndicator from '../../components/LiveIndicator/LiveIndicator'
import { useIsTimePassed } from '../../hooks/useIsTimePassed'
import dayjs from 'dayjs'
import config from '../../config'
import { vuorovaikutusLoginOnce } from '../../api/vuorovaikutus-api/login'

const USE_VUOROVAIKUTUS_API = config.useVuorovaikutusApi

const Frontpage: FC = () => {
  const [isInfoModal, setIsInfoModal] = React.useState(false)
  const showLeaderboard = useIsTimePassed(dayjs(config.revealLeaderboardButtonAfterUTC))
  const { isLive, isLoading, nextEpisode } = useSeries()
  const navigate = useNavigate()

  useEffect(() => {
    if (USE_VUOROVAIKUTUS_API) {
      vuorovaikutusLoginOnce()
    }
  }, [])

  if (isLoading) {
    return (
      <Background overlay="purple" hasGoldenEdge>
        <PageLoadingIndicator color="white" />
      </Background>
    )
  }

  return (
    <Background overlay="purple" hasGoldenEdge>
      <div className={styles.frontpage}>
        <div className={styles.header}>
          <Logo />
          {nextEpisode && (
            <SignBoard>
              <MessageContainer
                left={<LiveIndicator episodeName={nextEpisode.name} isLive={isLive} />}
                title={nextEpisode.mainText}
                text={`${strings.departure} ${nextEpisode.startTime?.format(strings.timeFormat)}`}
              />
            </SignBoard>
          )}
        </div>
        <p className={styles.infoText}>
          {strings.frontpageText}
          <br />
          <br />
          {strings.frontpageText2}
        </p>
        <div className={styles.frontpageButtonContainer}>
          <Button
            isDisabled={!isLive}
            onClick={() => navigate(`${ROUTES.gamepage}/${nextEpisode?.uuid}${ROUTES.gamelobby}`)}
            iconAfter={<ChevronRight />}
          >
            {strings.play}
          </Button>
          {showLeaderboard && (
            <Button
              onClick={() => navigate(ROUTES.resultspage)}
              iconAfter={<ChevronRight />}
              variant="transparent"
            >
              {strings.showResults}
            </Button>
          )}
          <Button onClick={() => setIsInfoModal(!isInfoModal)} variant="text">
            {strings.info}
          </Button>
        </div>
        {isInfoModal && <InfoModal onClose={() => setIsInfoModal(false)} />}
      </div>
    </Background>
  )
}

export default Frontpage
